import { createRouter, createWebHistory } from 'vue-router';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import store from '../store';

// const authenticate = (to, from, next) => {};

const authenticate = (to, from, next) => {
  const getCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  let user = getCookie('user');
  user = Base64.parse(user);
  user = Utf8.stringify(user);
  if (user) {
    user = JSON.parse(user);
    store.commit('SET_CURRENT_USER', user);
  }
  if (user) {
    next();
  } else {
    next({ name: 'Login' });
  }
};

// const checkOrder = (to, from, next) => {
//   if (store.state.singleOrderPayload) {
//     next();
//   } else {
//     next({ name: 'create-order' });
//   }
// };

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/landing',
    beforeEnter: authenticate,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/RegisterV2.vue'),
  },
  {
    path: '/validate/:code?',
    name: 'Validate',
    component: () => import('@/views/Validate.vue'),
  },
  {
    path: '/reset/:sub/:token',
    name: 'Reset Password',
    component: () => import('@/views/ResetPassword.vue'),
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/landing',
    name: 'Landing Page',
    beforeEnter: authenticate,
    component: () => import('@/views/Landing.vue'),
  },
  {
    path: '/panel',
    redirect: '/panel/dashboard',
    name: 'SAMPOERNA',
    component: () => import('@/components/layouts/Main.vue'),
    beforeEnter: authenticate,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/panel/Dashboard.vue'),
      },
      {
        path: 'create-order',
        name: 'Buat Pesanan',
        component: () => import('@/views/panel/CreateOrder.vue'),
      },
      {
        path: 'order-details',
        name: 'Rincian Pesanan',
        // beforeEnter: checkOrder,
        component: () => import('@/views/panel/TransactionDetails.vue'),
      },
      {
        path: 'history',
        name: 'History PO',
        component: () => import('@/views/panel/History.vue'),
      },
      {
        path: 'role',
        name: 'Kelola Peran',
        component: () => import('@/views/panel/Role.vue'),
      },
      {
        path: 'transaction',
        name: 'Transaksi',
        component: () => import('@/views/panel/Transaction.vue'),
      },
      {
        path: 'settings',
        redirect: 'settings/change-password',
        name: 'Settings',
        children: [
          {
            path: 'change-password',
            name: 'Change Password',
            component: () => import('@/views/panel/ChangePassword.vue'),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
